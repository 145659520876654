export const autopayDetails = (item?: {
  autopayInstitution?: string;
  autopayAccount?: string;
  autopayTransit?: string;
  autopayStatus?: string;
  autopayClientId?: string;
}) => {
  const { autopayInstitution, autopayAccount, autopayTransit, autopayStatus, autopayClientId } =
    item ?? {};

  return autopayInstitution && autopayAccount && autopayTransit && autopayStatus
    ? {
        autopayInstitution,
        autopayAccount,
        autopayTransit,
        autopayStatus,
        autopayClientId,
      }
    : undefined;
};
