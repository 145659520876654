import { useGetBooksActivationQuery } from 'api';
import { useFlags } from 'flagsmith/react';
import { useAccountingEvents } from 'pages/accounting/hooks';

export const useBooksActivated = () => {
  const flags = useFlags(['autoap']);
  const { data, loading } = useGetBooksActivationQuery();

  const activated = Boolean(data?.account?.books?.activatedZ);
  const autopay = Boolean(data?.account?.books?.autopay?.activated);
  const autopayVersion = data?.account?.books?.autopay?.version;
  const autopayActivationStatus = data?.account?.books?.autopay?.activationStatus;
  const enablePayments = data?.account?.books?.autopay?.enablePayments;
  const enableCollection = data?.account?.books?.autopay?.enableCollection;

  useAccountingEvents(activated);

  const autopayV2 = flags.autoap.enabled && autopay && autopayVersion === '2';

  return {
    activated,
    autopay,
    autopayV2,
    autopayVersion,
    autopayActivationStatus,
    enablePayments,
    enableCollection,
    loading,
  };
};
